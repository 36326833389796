// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-connect-js": () => import("./../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-js": () => import("./../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-connekt-js": () => import("./../src/pages/work/connekt.js" /* webpackChunkName: "component---src-pages-work-connekt-js" */),
  "component---src-pages-work-halowheelmen-js": () => import("./../src/pages/work/halowheelmen.js" /* webpackChunkName: "component---src-pages-work-halowheelmen-js" */),
  "component---src-pages-work-polymorph-crafts-js": () => import("./../src/pages/work/polymorph-crafts.js" /* webpackChunkName: "component---src-pages-work-polymorph-crafts-js" */),
  "component---src-pages-work-romantic-era-lyrics-js": () => import("./../src/pages/work/romantic-era-lyrics.js" /* webpackChunkName: "component---src-pages-work-romantic-era-lyrics-js" */),
  "component---src-pages-work-spectrumtv-js": () => import("./../src/pages/work/spectrumtv.js" /* webpackChunkName: "component---src-pages-work-spectrumtv-js" */)
}

